.panelHeader {
}

.panelHeader > h4 {
    color:white;
    text-align:center;
}

.controlPanel {
    position: absolute;
    background-color: var(--theme-grey-color);
    z-index: 1;
    top: 0px;
    opacity: 0.1;
    left: -300px;
    width: 300px;
    height: 100%;
}

.panelSubtitle {
    font-size: medium;
    margin-left: 10px;
}

.controlLabel {
    color:white;
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.slideIn {
    left: 55px;
    opacity: 1;
    transition: .0s;
}

.closeButton {
    float:right;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    opacity: .6;
}

.closeButton:hover {
    opacity: 1;
}