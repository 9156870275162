.secondaryText {
  margin-left: 10px;
  margin-top: 0px;
  color: lightgray;
}

.link {
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 0px;
  color: lightgray;
  display: block;
}

.fa {
  padding: 12px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin-right: 4px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.socialButtons {
  margin-left: 10px;
}