.container {
    height: 95%;
}

.effectGrid {
    margin-top: 10px;
    height: 90%;
    overflow: scroll;
}

.loaderSpinner {
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 160px;
  margin: auto;
}

.searchInput {
    width: 274px;
    height: 26px;
}

input[type=text] {
    border: 2px solid var(--theme-light-grey-color);
    background-color: transparent;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: .3s ease;
    color: rgba(255,255,255,.7);
    font-size: 15px;
    letter-spacing: 2px;
}

input[type=text]:focus {
    outline: none !important;
    border: 2px solid var(--secondary-color) !important;
    transition: 0.2s;
}

input[type=text]:hover {
    border: 2px solid grey;
}