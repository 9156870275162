.effectSelectionItem {
    margin: 15px;
    background-image: url('./square-bg.png');
    padding: 5px;
    background-size: 40px 40px;
    background-position: 50% 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.effectName {
    color: #D4D5D6;
    text-align: center;
}