.layerBar {
  z-index: 2;
  position: absolute;
  top:0;
  right:0;
  width: 100px;
  height: 100%;
  background-color: var(--theme-grey-color);
  display:flex;
  flex-flow:column;
}

.layerList {
  overflow-y: scroll;
}

.layerBar > h4 {
    color:white;
    text-align:center;
}