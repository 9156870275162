html,
body {
  background-color: black !important;
}

#container {
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  z-index: 10;
  margin-top: 30px;
}

.title h4 {
  color: white;
  text-align: center;
  font-size: 1.25em;
}

@media screen and (min-width: 768px) {
  .subtitleText {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 150px;
  }
}

@media screen and (max-width: 760px) {
  .subtitleText {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  }
}

.title img {
  display: block;
  margin: auto;
}

.appStoreBadge {
  margin-top: 20px !important;
}

.description {
  display:block;
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}