.photoUrl {
    width: 300px;
    height: 26px;
    display:block;
}

.photoStuff {
    color: darkgray;
    margin: 0;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    text-align: center;
    transform: translate(-50%, -50%) 
}

.openImageLabel {
    color: lightgray;
}

.orLabel {
    color: darkgrey;
}

.pickPhotoBtnContainer {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%,-50%);
    width: 600px;
    height: 400px;
    display:inline-block;
}