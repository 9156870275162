body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.canvas-container {
    position: absolute;
    background-color: lightgray;
    top: 50%; 
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.vimageScene {
  height: 100%;
}

@font-face {
    font-family: test-family;
    src: url(../../static/media/flame.497d7f89.ttf);
  }
.photoUrl {
    width: 300px;
    height: 26px;
    display:block;
}

.photoStuff {
    color: darkgray;
    margin: 0;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%) 
}

.openImageLabel {
    color: lightgray;
}

.orLabel {
    color: darkgrey;
}

.pickPhotoBtnContainer {
    position: absolute;
    top: 50%; 
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 600px;
    height: 400px;
    display:inline-block;
}
.exportPanel {
    margin: 0 auto;
    width: 320px;
    margin-top: 10px;
    margin-bottom: -10px;
}

.exportButton:hover {
    background: #10ddba;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.previewButton:hover {
    background: rgb(48, 51, 56);
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.exportButton {
    background: var(--secondary-color);
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.previewButton {
    background: #292C31;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.vimageButton {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: 6px;
    font-size: 14px;
    font-family: "Open Sans";
    height: 30px;
    line-height: 30px;
    width: 150px;
    text-align: center;
    letter-spacing: 2px;
    margin: 5px;
    opacity: 0.9;
    color: #fff !important;
    background-size: 100%;
    background-position: 50% 50%;
}
.panelHeader {
}

.panelHeader > h4 {
    color:white;
    text-align:center;
}

.controlPanel {
    position: absolute;
    background-color: var(--theme-grey-color);
    z-index: 1;
    top: 0px;
    opacity: 0.1;
    left: -300px;
    width: 300px;
    height: 100%;
}

.panelSubtitle {
    font-size: medium;
    margin-left: 10px;
}

.controlLabel {
    color:white;
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.slideIn {
    left: 55px;
    opacity: 1;
    -webkit-transition: .0s;
    transition: .0s;
}

.closeButton {
    float:right;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    opacity: .6;
}

.closeButton:hover {
    opacity: 1;
}
.effectSelectionItem {
    margin: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAA5klEQVR4nO3bwQlCQQxAwVX+XS1AEOy/NrUCLeLlIOxMAWF55Bb2dH88v2vQ9XKbHLfen9fovOn3nUenbUjASMBIwEjASMBIwEjASMBIwEjASMBIwEjASMBIwEjASMBIwOjY7YYx/T4bGAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJGB273TD8E/kzAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJGAkYCRgJG/olENjASMBIwEjASMBIwEjASMBIwEjASMBIwEjASMBIwEjASMBIwErBYa/0A5LgXNUbxnHYAAAAASUVORK5CYII=);
    padding: 5px;
    background-size: 40px 40px;
    background-position: 50% 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.effectName {
    color: #D4D5D6;
    text-align: center;
}
.container {
    height: 95%;
}

.effectGrid {
    margin-top: 10px;
    height: 90%;
    overflow: scroll;
}

.loaderSpinner {
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 160px;
  margin: auto;
}

.searchInput {
    width: 274px;
    height: 26px;
}

input[type=text] {
    border: 2px solid var(--theme-light-grey-color);
    background-color: transparent;
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transition: .3s ease;
    transition: .3s ease;
    color: rgba(255,255,255,.7);
    font-size: 15px;
    letter-spacing: 2px;
}

input[type=text]:focus {
    outline: none !important;
    border: 2px solid var(--secondary-color) !important;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

input[type=text]:hover {
    border: 2px solid grey;
}
.textEdit {
    width: 274px;
    height: 60px;
    border: 2px solid var(--theme-light-grey-color);
    background-color: transparent;
    overflow: hidden;
    color: rgba(255,255,255,.7);
    font-size: 15px;
    letter-spacing: 2px;
    resize: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transition: .3s ease;
    transition: .3s ease;
}

.textEdit:focus {
    outline: none !important;
    border: 2px solid var(--secondary-color) !important;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.textEdit:hover {
    border: 2px solid grey;
}

.addBtn {
    margin-top: 10px;
    margin-left: 10px;
}

.fontSelect {
    width: 274px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.colorPicker {
    margin-left: 10px;
}

.toolbar {
    z-index: 2;
    position: absolute;
    top:0;
    left:0;
    width: 50px;
    height: 100%;
    background-color: var(--theme-grey-color);
    display:-webkit-flex;
    display:flex;
    -webkit-flex-flow:column;
            flex-flow:column;
}

.toolbarMenu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 2 1;
            flex: 2 1;
}

.toolbarMenu > ul {
    list-style-type: none;
    padding: 0;
}

.splitter {
    height: 10px;
}

.menuBtn {
    width: 40px;
    height: 40px;
}

.menuLogo {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    opacity: 0.8;
}

.menuLogo:hover {
    opacity: 1;
}

.effectButton {
    width: 28px;
    height: 28px;
    margin: 11px;
}
.toolbarItem {
    display: block;
    width: 50px;
    height: 50px;
    border-left: solid 2px rgba(0,0,0,0);
}

.active {
    border-left: solid 2px #10ddba;
    background-color: rgba(255, 255, 255, 0.02);
}

.toolbarUnavailItem > img {
    display: block;
    width: 50px;
    height: 50px;
    border-left: solid 2px rgba(0,0,0,0);
    margin-left: 9px;
    margin-right: 11px;
    margin-top:11px;
    width: 28px;
    height: 28px;
    opacity: 0.4;
}

.toolbarItem:hover > img {
    opacity: 1;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.toolbarItem > img {
    margin-left: 9px;
    margin-right: 11px;
    margin-top:11px;
    width: 28px;
    height: 28px;
    opacity: 0.7;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

.toolbarItem.active > img {
    opacity: 1;
}
.layerItem {
  width: 70px;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme-light-grey-color);
  margin: 10px;
}

.layerItem:hover {
  border-color: var(--secondary-color)
}
.layerBar {
  z-index: 2;
  position: absolute;
  top:0;
  right:0;
  width: 100px;
  height: 100%;
  background-color: var(--theme-grey-color);
  display:-webkit-flex;
  display:flex;
  -webkit-flex-flow:column;
          flex-flow:column;
}

.layerList {
  overflow-y: scroll;
}

.layerBar > h4 {
    color:white;
    text-align:center;
}
.shapeSelect {
  width: 274px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.colorPicker {
  margin-right: 20px;
}
.secondaryText {
  margin-left: 10px;
  margin-top: 0px;
  color: lightgray;
}

.link {
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 0px;
  color: lightgray;
  display: block;
}

.fa {
  padding: 12px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin-right: 4px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.socialButtons {
  margin-left: 10px;
}
html,
body {
  background-color: black !important;
}

#container {
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  z-index: 10;
  margin-top: 30px;
}

.title h4 {
  color: white;
  text-align: center;
  font-size: 1.25em;
}

@media screen and (min-width: 768px) {
  .subtitleText {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 150px;
  }
}

@media screen and (max-width: 760px) {
  .subtitleText {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  }
}

.title img {
  display: block;
  margin: auto;
}

.appStoreBadge {
  margin-top: 20px !important;
}

.description {
  display:block;
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
html,
body {
  background-color: #171719;
  background-color: var(--theme-black-color);
  height: 100%;
  overflow: hidden;
}

:root {
  --secondary-color: #12bb9f;
  --theme-grey-color: #292C31;
  --theme-light-grey-color: rgb(95, 96, 97);
  --theme-black-color: #171719;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #292C31;
  background: var(--theme-grey-color); 
}

::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

h3 {
  color: white;
}

p {
  color: white;
}

label {
  color: white;
}

.vimage {
  height: 100vh;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 4px;
}

kbd {
  padding:0.1em 0.6em;
  border:1px solid #ccc;
  font-size:11px;
  font-family:Arial,Helvetica,sans-serif;
  background-color:#f7f7f7;
  color:#333;
  box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
  border-radius:3px;
  display:inline-block;
  margin:0 0.1em;
  text-shadow:0 1px 0 #fff;
  line-height:1.4;
  white-space:nowrap;
}
