.exportPanel {
    margin: 0 auto;
    width: 320px;
    margin-top: 10px;
    margin-bottom: -10px;
}

.exportButton:hover {
    background: #10ddba;
    transition: .2s linear;
}

.previewButton:hover {
    background: rgb(48, 51, 56);
    transition: .2s linear;
}

.exportButton {
    background: var(--secondary-color);
    transition: .2s linear;
}

.previewButton {
    background: #292C31;
    transition: .2s linear;
}

.vimageButton {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: 6px;
    font-size: 14px;
    font-family: "Open Sans";
    height: 30px;
    line-height: 30px;
    width: 150px;
    text-align: center;
    letter-spacing: 2px;
    margin: 5px;
    opacity: 0.9;
    color: #fff !important;
    background-size: 100%;
    background-position: 50% 50%;
}