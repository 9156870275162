.textEdit {
    width: 274px;
    height: 60px;
    border: 2px solid var(--theme-light-grey-color);
    background-color: transparent;
    overflow: hidden;
    color: rgba(255,255,255,.7);
    font-size: 15px;
    letter-spacing: 2px;
    resize: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: .3s ease;
}

.textEdit:focus {
    outline: none !important;
    border: 2px solid var(--secondary-color) !important;
    transition: 0.2s;
}

.textEdit:hover {
    border: 2px solid grey;
}

.addBtn {
    margin-top: 10px;
    margin-left: 10px;
}

.fontSelect {
    width: 274px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.colorPicker {
    margin-left: 10px;
}