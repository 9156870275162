html,
body {
  background-color: var(--theme-black-color);
  height: 100%;
  overflow: hidden;
}

:root {
  --secondary-color: #12bb9f;
  --theme-grey-color: #292C31;
  --theme-light-grey-color: rgb(95, 96, 97);
  --theme-black-color: #171719;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--theme-grey-color); 
}

::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

h3 {
  color: white;
}

p {
  color: white;
}

label {
  color: white;
}

.vimage {
  height: 100vh;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

kbd {
  padding:0.1em 0.6em;
  border:1px solid #ccc;
  font-size:11px;
  font-family:Arial,Helvetica,sans-serif;
  background-color:#f7f7f7;
  color:#333;
  -moz-box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
  -webkit-box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
  box-shadow:0 1px 0px rgba(0, 0, 0, 0.2),0 0 0 2px #ffffff inset;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  border-radius:3px;
  display:inline-block;
  margin:0 0.1em;
  text-shadow:0 1px 0 #fff;
  line-height:1.4;
  white-space:nowrap;
}