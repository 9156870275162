.canvas-container {
    position: absolute;
    background-color: lightgray;
    top: 50%; 
    left: 50%;
    transform: translate(-50%,-50%);
}

.vimageScene {
  height: 100%;
}

@font-face {
    font-family: test-family;
    src: url('../../flame.ttf');
  }