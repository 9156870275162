
.toolbar {
    z-index: 2;
    position: absolute;
    top:0;
    left:0;
    width: 50px;
    height: 100%;
    background-color: var(--theme-grey-color);
    display:flex;
    flex-flow:column;
}

.toolbarMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
}

.toolbarMenu > ul {
    list-style-type: none;
    padding: 0;
}

.splitter {
    height: 10px;
}

.menuBtn {
    width: 40px;
    height: 40px;
}

.menuLogo {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    opacity: 0.8;
}

.menuLogo:hover {
    opacity: 1;
}

.effectButton {
    width: 28px;
    height: 28px;
    margin: 11px;
}