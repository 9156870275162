.toolbarItem {
    display: block;
    width: 50px;
    height: 50px;
    border-left: solid 2px rgba(0,0,0,0);
}

.active {
    border-left: solid 2px #10ddba;
    background-color: rgba(255, 255, 255, 0.02);
}

.toolbarUnavailItem > img {
    display: block;
    width: 50px;
    height: 50px;
    border-left: solid 2px rgba(0,0,0,0);
    margin-left: 9px;
    margin-right: 11px;
    margin-top:11px;
    width: 28px;
    height: 28px;
    opacity: 0.4;
}

.toolbarItem:hover > img {
    opacity: 1;
    transition: .2s linear;
}

.toolbarItem > img {
    margin-left: 9px;
    margin-right: 11px;
    margin-top:11px;
    width: 28px;
    height: 28px;
    opacity: 0.7;
    transition: .2s linear;
}

.toolbarItem.active > img {
    opacity: 1;
}