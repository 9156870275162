.layerItem {
  width: 70px;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme-light-grey-color);
  margin: 10px;
}

.layerItem:hover {
  border-color: var(--secondary-color)
}